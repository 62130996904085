import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom';
import Intro from "./intro-bg8.MP4"
import loadingGif from "./intro-bg8.gif"


export const Header = (props) => {
  return (
    <header id='header'>
        <div>
          <img          
          style={{ 
          position: "absolute",
          objectFit: "cover",
          width: "100%",
          height: "100%",
          resize: "horizontal",
          overflow: "auto",
          height: "calc(100vh - 16px)"

        }} src={loadingGif} alt="wait until page loads" alt="" />{" "}
        </div>
      <div className='intro'>
        
          <div className='col-md-8 col-md-offset-2 intro-text'>
              <h5 style={{
                fontFamily: "AnimeAce"
                
              }}>
                
                </h5>
                <Fade botom>
                <a style={{
                  fontFamily: "AnimeAce",
                  marginTop: "15px"
                  
                }}
                  href='#about'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  LFG
                </a>{' '}
                </Fade>
                <h5>
                  
                </h5>


              </div>
          <div className='container'>
            <div className='row'>
              
            </div>
          </div>
        
      </div>
    </header>
  )
}
