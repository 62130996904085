import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Contact } from "./components/contact";
import { Documentation } from "./components/documentation";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { MetaMaskProvider } from "metamask-react";
import { Meet } from "./components/meettheAngelz";
import { Launch } from "./components/launch";







const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);



  return (

    <div>

      <Navigation />
      <Header data={landingPageData.Header} /> 
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Meet data={landingPageData.Meet} />
      <Contact data={landingPageData.Contact} />
    </div>

    
    



  );
};

export default App;
