import Fade from 'react-reveal/Fade'
import loadingGif from "./Video.gif"
import Zoom from 'react-reveal/Zoom';

export const About = (props) => {
  return (
    <div id="about">
      <Fade bottom>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src={loadingGif} alt="wait until page loads" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
            <Zoom right> 
              <h2 style={{fontFamily: "RapierZero", letterSpacing: "3"}}>Intro</h2>
              </Zoom>
              <p style={{
                color: "black"
              }}>{props.data ? props.data.paragraph : "loading..."}</p>
              <h6 style={{
                color:"#d00087"
              }}>PLURcoin holders gain exclusive access to the pre-sale.</h6>
              <div className="list-style">
                <div className="">
                <p style={{
                  color: "black"
                }}>{props.data ? props.data.paragraph2 : "loading..."}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      </Fade>
    </div>
    
  );
};
