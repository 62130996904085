import React, { useEffect, useState } from "react";
import emailjs from 'emailjs-com'
import CountdownTimer from './countdown'
import './countdown.css'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade'
import {FaTwitter, FaFacebook, FaInstagram, FaDiscord} from "react-icons/fa"
import { MDBFooter,MDBContainer,MDBCol,MDBRow,MDBIcon, MDBNavbar,MDBNavbarBrand,MDBNavbarLink, } from 'mdb-react-ui-kit';
import PLUR from "./PLUR.png"
import "./plur.css"



const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)
  const [Message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_8cyr6cf', 'template_0koo5jf', e.target, 'TtcKG0LCV4-mP5FnV'
      )
      .then(
        (result) => {
          console.log(result.text, result.status);
          clearState();
          setMessage("Email sent successfully")
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  return (
    <div>
      <div id='contact'>
        <div className='container'>
        <Fade bottom>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <Zoom right> 
                <h2 style={{fontFamily: "RapierZero", letterSpacing: "3"}}>guest list</h2>
                </Zoom>
                <p>
                Get on the list! Or have Questions? Send a message to the concierge.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          </Fade>
          <div className='col-md-12'>
          <div>
       <p style={{
         fontSize: "15px",     
       }}>{Message}</p>
          </div>
            <div className='row'>
              <div className='social'>
                <ul>
                <MDBContainer className='p-4 pb-0'>
                    <section className=''>
                    <a style={{fontSize: "32px"}} target = {"_blank"} className='btn btn-outline-white btn-floating m-1' href='https://discord.gg/SY4DTqFcNt' role='button'>
                    <FaDiscord fab icon='facebook-f' />
                    </a>

                    <a style ={{fontSize: "30px"}} target = {"_blank"} className='btn btn-outline-white btn-floating m-1' href='https://twitter.com/RaveAngelz_' role='button'>
                    <FaTwitter fab icon='google' />
                    </a>
                    <a  target = {"_blank"} className='btn btn-outline-white btn-floating m-1' href='https://plurcoin.com' role='button'>
                    <img src={PLUR} className= "PLUR" style ={{height: "28px", width: "28px"}} fab icon='google' />
                    </a>
                    </section>
                </MDBContainer>

                </ul>  
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p style ={{fontFamily: "AnimeAce"}}>
            &copy; 2022 RaveAngelz{' '}
            <a href='http://www.raveangelz.io' rel='nofollow'>
              
            </a>
          </p>
          <div>
          <a style= {{ 
            
            fontFamily: "Robot", 
            textDecoration: "none", 
            color: "#888"}}href='/Documentation'  target = {"_blank"}>
            Documentation
          </a>
          <a style= {{ 
            textDecoration: "none", 
            fontFamily: "Robot", 
            color: "#888",
            padding: "10px"}}href='/Terms'  target = {"_blank"}>
            Terms of Service
          </a>
          <a style= {{ 
            textDecoration: "none", 
            fontFamily: "Robot", 
            color: "#888"
            }}href='/Privacy'  target = {"_blank"}>
            Privacy policy
          </a>
          </div>
        </div>
      </div>
    </div>
    
    
  )
}
