import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import { Launch } from './launch';

export const Services = (props) => {
  return (
    <div id='services' className='secion-title'>
      <Fade Top>
      <div className='container'>
        <div className='section-title'>
        <Zoom right> 
          <h2 style={{fontFamily: "RapierZero", letterSpacing: "3"}}>PLURpod</h2>
          </Zoom>
          <Zoom>
          <p>
          Your Rave Angel gives you access to the PLURpod, an interactive club within the Metaverse where you can show off your NFT collection and play music directly from the blockchain. Hang out with other Angelz while the PLURpod hosts live performances from some of the greatest DJs in the metaverse. The PLURpod token will be distributed once all Angelz are claimed.

           </p>
           </Zoom>
           <Zoom>
          <h6 style={{
            marginTop: "40px",
            fontStyle: "italic"
            
          }}>
          Pardon our dust - under renovation before grand opening.
          </h6>
          </Zoom>
        </div>
        <div className="plurpod">
            {" "}
            <img src="img/plurpod1.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="">
            {" "}
            <img style={{marginTop:"20px"}} src="img/plurpod2.jpg" className="img-responsive" alt="" />{" "}
          </div>

      </div>
      </Fade>

    </div>

  )
}
