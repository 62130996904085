import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import "./docs.css"
import { Navigation } from './navigation';




export const Documentation = (props) => {
  return (
    <div id='docs' className='secion-title'>
      <Fade Top>
      <div className='container'>
        <div className='section-title'>
        <Zoom right> 
          <h2 style={{fontFamily: "RapierZero", letterSpacing: "3"}}>Documentation</h2>
          </Zoom>

          <h1>Smart contract. </h1>
          <Zoom>
          <p>
          The Rave Angelz will be an Ethereum collection. An optimized ERC721A smart contract with a fixed supply of 1 thousand. Each token will have its own unique number and metadata. Some tokens will be rarer than others. The mint will cost 0.02 ETH plus gas. We have set the gas fee to 285000. Please do not alter the gas fee as your Angel will not mint.
          </p>
          <h1>PLURcoin holders </h1>
          <p>If you are a PLURcoin holder you are entitled to claim one Angel for free. You will just pay for gas fees to mint. You have to hold PLURcoin in your Ethereum wallet in order to be eligible. PLURcoin holders will have a certain amount of time to claim their Angel. Only one Angel is permitted for each PLURcoin holding wallet address during the pre-sale.  </p>
          <h1>PLURpod.</h1>
          <p>The PLURpod, is your dancefloor in the Metaverse. Use it to display your favorite jpegs or music and share it with your friends. The PLURpod is the beginning of the future development of the Rave Angel metaverse takeover. The PLURpod is currently been developed. Once all 1 thousand Angelz are distributed, all Angel holders will be airdropped the PLURpod NFT token.  
            </p>
            <h1>DAO</h1>
            <p>All money generated from the mint of the Angelz collection will form the Kandi Dao. The Kandi Dao will act as governance for the Rave Angel ecosystem. The Kandi token will become available to all Members of the Rave Angel ecosystem once all Angelz are claimed. The more Anglez you have, the more Kandi you will receive. </p>
           </Zoom>
        </div>
        <a style= {{ 
            textDecoration: "none", 
            fontSize: "15px", 
            }}href='/'  target = {"_blank"}>
            Back to Site
          </a>
      </div>
      </Fade>
    </div>

  )
}
