export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='#page-top' style={{fontFamily:"RapierZero", letterSpacing:"2"}}>
            Rave Angelz
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a  href='#about' className='page-scroll'>
                Intro
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                PLURpod
              </a>
            </li>

            <li>
              <a target = {"_blank"} href='https://raveangelzmint.netlify.app/'>
                Pre-Order
              </a>
            </li>
            <li>
              <a style ={{cursor: "pointer"}}onClick={() => alert('Do you like Kandi? Coming Soon')}>
                DAO
              </a>
            </li>
            <li>
              <a href='#meet' className='page-scroll'>
                Meet
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
