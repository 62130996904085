import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Zoom';

export const Meet = (props) => {
  return (
    <div id="meet">
      <Fade bottom>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src= "img/angelPlain2.png" alt="wait until page loads" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="meet-text">
            <Zoom right> 
              <h2 style={{fontFamily: "RapierZero", letterSpacing: "3"}}>Meet Us</h2>
              </Zoom>
              <p style={{
                color: "black"
              }}>{props.data ? props.data.paragraph : "loading..."} </p>
              <h6 style={{
                color: "#d00087"
              }}> A quick note from Anabelle</h6>
              <div className="list-style">
                <div className="">
                <p style={{
                color: "black" }}>{props.data ? props.data.paragraph2 : "loading..."}
              
              </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Fade>
    </div>
    
  );
};
